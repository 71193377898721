@import './variables';
@import './mixins';

.bg-tertiary {
  background-color: var(--tertiary);
}

.color-tertiary {
  color: var(--tertiary);
}

.bg-primary-light {
  background-color: $primary-light;
}

.c-primary-light {
  color: $primary-light;
}

.bg-primary-lightest {
  background-color: $primary-lightest;
}

.c-primary-lightest {
  background-color: $primary-lightest;
}

.bg-primary-dark {
  background-color: $primary-dark;
}

.c-primary-dark {
  color: $primary-dark;
}

.bg-primary-dark {
  background-color: $primary-dark;
}

.c-primary-darkest {
  background-color: $primary-darkest;
}

@include color_values('blue-01', $blue-01);
@include color_values('blue-02', $blue-02);
@include color_values('blue-03', $blue-03);
@include color_values('blue-04', $blue-04);
@include color_values('blue-05', $blue-05);
@include color_values('blue-06', $blue-06);
@include color_values('blue-07', $blue-07);

@include color_values('gray-01', $gray-01);
@include color_values('gray-02', $gray-02);
@include color_values('gray-03', $gray-03);
@include color_values('gray-04', $gray-04);

@include color_values('white-01', $white-01);
