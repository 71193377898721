@import '../global/variables';

// card
$card-radius: 0;

.card {
    border-color: var(--border-color);
    background-color: var(--card-color);
    border-style: dashed;
    border-radius: $card-radius;
    border: 0 none;
    box-shadow: 0 3px 5px 3px rgba(0, 0, 0, 0.2);

    .card-header {
      border-radius: $card-radius;
      border: 0 none;
      font-size: 1.2rem;
      color: $white;
    }

    &.call-to-action {
      border-radius: 8px;
      box-shadow: none;
      padding: 0;
      width: 100%;
      max-width: 480px;
      margin-bottom: 16px;

      .card-body {

        padding: 32px 40px;

        .btn {
          margin-bottom: 20px;
        }

        .card-text {
          font-size: 16px;
          line-height: 24px;
          margin-bottom: 4px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    hr {
      border-color: #BFC7D3;
    }

    ul {
      padding-left: 16px;

      li {
        text-align: left;
        font-weight: 700;
        margin-bottom: 4px;

        .card-text {
          font-size: 18px;
          line-height: 18px;
        }
      }
    }

    .action.phone {
      font-size: 20px;
      line-height: 20px;
      color: $blue-05;
      font-weight: 700;
    }
}

@media screen and (max-width: $break-sm) {
  .card.call-to-action  {
    .card-body {
      padding: 24px;
    }
  }

}
