@import '../global/_variables.scss';


[data-bs-theme="nna-light"] {

  // scss-docs-start colors-map
  $c_colors: (
      "black":        $black,
      "blue":         $blues,
      "blush":        $blush,
      "indigo":       $indigos,
      "purple":       $purples,
      "red":          $reds,
      "orange":       $oranges,
      "yellow":       $yellow,
      "green":        $greens,
      "cyan":         $cyans,
  ) !default;

	--primary-color: 	var(--primary);
	--secondary-color: 	var(--secondary);
  --bs-body-bg: #f8f8f8;

	--primary-rgb: 72, 76, 127;
}
