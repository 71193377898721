@import '../global/variables';

.modal-dialog.filters {
  .modal-header {
    .modal-title {
      font-size: 18px;
      font-weight: 700;
      width: 100%;
      text-align: center;
      margin-right: -32px;
    }
  }

}

@media screen and (min-width: 576px) {
  .modal-dialog.filters {
    max-width: 400px;
  }
}
