.form-control,
.form-select {
  border-color: #DEE1E4;
  border-radius: 8px;
}

.form-control.form-control-xl {
  height: 54px;
}

.form-label.form-label-xl {
  font-size: 16px;
  font-weight: 700;
}

.form-select-xl {
  height: 54px;
  background-color: #F8F8F8;

  &:hover{
    border-color: darken(#DEE1E4, 10%);
  }

  &:focus {
    background-color: #F8F8F8;
    border-color: darken(#DEE1E4, 20%);
    color: #333;
    box-shadow: none;
  }
}

.input-group {

  .btn {
    border-radius: 8px;
  }
  .clear {
    color: #B4B8BD;
  }

  &.merged {
    border: 1px solid #DEE1E4;
    border-radius: 8px;
    transition: border-color 0.3s;

    &:hover{
      border-color: darken(#DEE1E4, 10%);
    }

    &:focus-within {
      border-color: darken(#DEE1E4, 20%);
    }

    .form-control {
      border: 0 none;

      &:focus {
        box-shadow: none;
        border: 0 none;
        color: #333;
      }
    }


    .btn {
      border: 0 none;
      font-size: 16px;

      &:hover {
        background-color: #fff;
        color: darken(#B4B8BD, 10%);
      }

      &:active {
        border: 0 none;
      }
    }
  }
}

$check-group-offset: 36px;

.check-group {
  .form-check {
    padding-left: $check-group-offset;
  }

  .form-check-input {
    height: 23px;
    width: 23px;
    margin-left: -$check-group-offset;
  }

  .form-check-label {
    line-height: 1.1em;
  }
}

.search-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  width: 100%;
  max-width: 600px;
  gap: 12px;

  .icon {
    position: absolute;
    height: 20px;
    width: 20px;
    font-size: 20px;
    line-height: 20px;
    top: 17px;
    left: 16px;
    z-index: 1;
    display: none;
  }

  .input-group {
    width: 462px;
    height: 54px;
    border-radius: 8px;
    box-shadow: 0px 4px 16px 0px rgba(0, 89, 223, 0.25);

    *:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      border: 0 none;
    }

    *:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 0 none;
    }

    input {
      border: 0 none;
      padding-left: 20px;
      padding-right: 20px;

      &:focus {
        color: $text-color;
        outline: none;
        box-shadow: none;
        z-index: 0;
      }
    }

    button {
      width: 56px;
      font-size: 20px;
      transition: background-color 0.3s, color 0.3s, border-color 0.3s;

      &:focus {
        outline: none;
        box-shadow: none;
      }

      &:hover {
        background-color: $primary-dark;
        border-color: $primary-dark;
        color: #fff;
      }

      &:active {
        font-size: 18px;
      }
    }
  }

  .btn-filter {
    background-color: $primary-lightest;
    box-shadow: 0px 4px 16px 0px rgba(0, 89, 223, 0.25);
    border-radius: 8px;
    color: $primary-dark;
    font-size: 16px;
    font-weight: 700;
    height: 54px;
    width: 102px;
  }

  .btn.btn-type-2 {
    gap: 12px;
  }
}

.pinned-search {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $blue-04;
  z-index: 1000;
  padding: 16px 0;

  &.show {
    display: flex;
    justify-content: center;
  }
}

@media screen and (max-width: 575px) {
  .search-box {
    position: relative;
    padding: 0;

    .icon {
      display: block;
    }

    .input-group {
      width: 100%;

      *:first-child  {
        border-top-right-radius: 8px !important;
        border-bottom-right-radius: 8px !important;
      }

      input {
        width: 100%;
        height: 54px;
        border-radius: 8px;
        border: 0 none;
        padding-left: 48px;
        padding-right: 48px;

        &:focus {
          color: $text-color;
          outline: none;
          box-shadow: none;
          z-index: 0;
        }
      }
    }

    .btn {
      position: absolute;
      height: 32px;
      width: 32px;
      font-size: 16px;
      padding: 0;
      line-height: 32px;
      top: 11px;
      right: 16px;

      &.btn-type-2 {
        box-shadow: none;
      }
    }
  }

  .pinned-search {
    padding: 16px 12px;
  }
}
