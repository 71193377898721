@import '../global/variables';

:root {

  --orange: #b95500;
  --red: #d73100;
  --purple: #8c4ee6;
  --blue: #076DE6;
  --dark-blue: #0A314C;
  --green: #008a63;
  --yellow: #cba002;
  --cyan: #00d5eb;
  --teal: #6efacc;
  --pink: #ff6783;

  --primary: #076DE6;
  --secondary: #444655;
  --tertiary: #0A314C;

	--dark-color: 		#262727;
  --text-color:     #202529;

	--color-fff:		#FFFFFF;
	--color-100:		#F0F0F0;
	--color-200:		#EEEEEE;
	--color-300:		#E0E0E0;
	--color-400:		#BDBDBD;
	--color-500:		#525252;
	--color-600:		#464545;
	--color-700:		#363535;
	--color-800:		#252525;
	--color-900:		#181818;
	--color-000:		#000000;

	--border-color:		#e9e6e1;
	--card-color: 		#ffffff;
	--body-color: 		#F8F8F8;
	--white-color: 		#ffffff;
	--sidebar-color:	#ffffff;

	--primary-color: 	--primary;
	--secondary-color: 	rgb(231, 225, 221);;

	--chart-color1:	 	#191A1C;
	--chart-color2: 	#837788;
	--chart-color3: 	#374765;
	--chart-color4: 	#484261;
	--chart-color5: 	#717788;
}
