@import '../global/colors';
@import '../global/variables';

.btn {
  letter-spacing: .01em;
}

.btn-type-1 {
  height: 40px;
  border-radius: 20px;
  padding: 12px 20px;
  background-color: $blue-05;
  color: $white-01;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background-color 0.3s ease;
  border: 0 none;

  &:hover {
    color: $white-01;
    background-color: $blue-06;
    border: 0 none;
  }

  &:focus {
    background-color: $blue-05;
    color: $white-01;
    border: 0 none;
  }

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    color: $white-01;
    background-color: $blue-07;
    border: 0 none;
  }
}

.btn-type-2 {
  height: 54px;
  padding: 16px;
  border-radius: 8px;
  border: 1px solid $blue-03;
  background-color: $blue-03;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  box-shadow: 0px 4px 16px 0px rgba(0, 89, 223, 0.25);
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;

  &:hover,
  &:focus {
    background-color: $blue-03;
    box-shadow: 0px 4px 16px 0px rgba(0, 89, 223, 0.25);
  }

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    background-color: $blue-03;
    border-color: $blue-05;
    box-shadow: 0px 4px 16px 0px rgba(0, 89, 223, 0);
  }
}

.btn-type-3 {
  padding: 12px 16px;
  border-radius: 4px;
  border: 0 none;
  background-color: $gray-02;
  font-size: 13px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;

  &:hover,
  &:focus {
    background-color: $gray-03;
  }

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    background-color: $gray-04;
  }
}

.btn-type-4 {
  padding: 12px 16px;
  border-radius: 4px;
  border: 0 none;
  color: $white-01;
  background-color: $blue-05;
  font-size: 18px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  transition: background-color 0.3s ease, border-color 0.3s ease, box-shadow 0.3s ease;

  &:hover,
  &:focus {
    color: $white-01;
    background-color: $blue-06;
  }

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    color: $white-01;
    background-color: $blue-07;
  }
}

.btn-type-5 {
  height: 40px;
  border-radius: 20px;
  padding: 12px 20px;
  background-color: $white-01;
  color: $blue-05;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  transition: background-color 0.3s ease;
  border: 1px solid $blue-05;

  &:focus {
    background-color: $white-01;
    color: $blue-05;
    border: 1px solid $blue-05;
  }

  &:hover {
    color: $blue-06;
    background-color: $blue-03;
    border: 1px solid $blue-05;
  }

  .btn-check:checked + &,
  :not(.btn-check) + &:active,
  &:first-child:active,
  &.active,
  &.show {
    color: $blue-06;
    background-color: $blue-03;
    border: 1px solid $blue-05;
  }
}

.btn.btn-text {
  font-weight: 700;
  padding: 0;
  border: 0 none;
  font-size: 16px;
  margin: 0;

  &:hover,
  &:focus,
  &:active {
    border: 0 none;
  }
}
