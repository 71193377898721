//Fonts Family
$font-family: 		'FigTree', sans-serif;
$font-figtree: 		'FigTree', sans-serif;
$font-opensans: 	'Open Sans', sans-serif;

// Fonts
$font-size: 		14px;
$min-contrast-ratio: 2.5;

// Bootstrap colors
$orange: #b95500;
$red: #d73100;
$purple: #8c4ee6;
$blue: #076DE6;
$green: #008a63;
$yellow: #cba002;
$cyan: #00d5eb;
$teal: #6efacc;
$pink: #ff6783;

$primary-lightest: #E0EFFF;
$primary-lighter: #DCE6F5;
$primary-light: #B0CCFE;
$primary-dark: #00437B;
$primary-darkest: #060A53;

$primary: $blue;
$secondary: #444655;
$tertiary: $primary-dark;

$color-success:     $green;
$color-danger: 	    $red;
$color-warning:     $yellow;
$color-info: 	    $cyan;
$color-secondary: 	$red;
$color-primary:     $blue;

// more color
$indigos: 		    #6610f2 !default;
$purples: 		    $purple !default;
$oranges:           $orange !default;
$yellow:            $yellow !default;
$greens:            $green !default;
$blues:	            $blue !default;
$blush:             #D86972 !default;
$cyans:	            $cyan !default;
$reds:	 			$red !default;
$info:		        $blue !default;
$white:		        #ffffff !default;
$dark:		        #191818 !default;
$black:		        #191A1C !default;

// blues
$blue-01: #E0EFFF;
$blue-02: #DCE6F5;
$blue-03: #CCE4FF;
$blue-04: #B0CCFE;
$blue-05: #076DE6;
$blue-06: #00437B;
$blue-07: #060A53;

// reds
$red-01: #FDDEDE;
$red-02: #FCD6D5;
$red-03: #F7AEB5;
$red-04: #EF3E42;
$red-08: #4B1214;

// grays
$gray-01: #EFF2F6;
$gray-02: #E0E4EA;
$gray-03: #D1D7E1;
$gray-04: #CBD2DD;
$gray-05: #415D75;

// whites
$white-01: #ffffff;

// text
$text-color: #202529;

// scss-docs-start colors-map
$c_colors: (
    "black":        $black,
    "blue":         $blues,
    "blush":        $blush,
    "indigo":       $indigos,
    "purple":       $purples,
    "red":          $reds,
    "orange":       $oranges,
    "yellow":       $yellow,
    "green":        $greens,
    "cyan":         $cyans,
) !default;

$sidebar-width: 	250px;
$rightbar-width: 	280px;
$sidebar-icon:	 	50px;

// Breakpoints
$break-sm: 		    567px;
$break-xsmall: 		640px;
$break-small: 		768px;
$break-medium: 		992px;
$break-1024: 		1024px;
$break-large: 		1200px;
$break-1280: 		1280px;
$break-xlarge: 		1440px;

// Forms
$border-radius: 4px;
